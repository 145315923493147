@supports (padding-top: constant(safe-area-inset-top)) {
  html {
    padding-top: constant(safe-area-inset-top);
  }
}

@supports (padding-top: env(safe-area-inset-top)) {
  html {
    padding-top: env(safe-area-inset-top);
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  body {
    -webkit-overflow-scrolling: touch;
  }
}

@supports (font: -apple-system-body) {
  html {
    font: -apple-system-body !important;
  }
  body {
    font-size: 0.875rem !important;
    line-height: 1.3 !important;
  }
  button div {
    font-size: 1.06rem !important;
  }
  h1 {
    font-size: 1.2rem !important;
    line-height: 1.3 !important;
  }
  /* Redeem screen */
  p {
    font-size: 0.85rem !important;
  }
  h2 {
    font-size: 1rem !important;
  }
}

body {
  margin: 0;
  font-family: OpenSans;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
  background-color: #EEF3F2;
  line-height: 18px;
  color: rgba(47, 56, 60, 0.75);
}

strong {
  font-weight: 600;
  color: #2f383c;
}

h1 {
  margin: 0;
  line-height: 26px;
  color: #2F383C;
  font-size: 20px;
  font-weight: 700;
}

h2 {
  margin: 0;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.0015em;
  color: #2f383c;
}

h3 {
  margin: 0;
  font-style: normal;
  font-weight:600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.0015em;
  color: #2F383C;
}

a {
  font-weight: 600;
  text-decoration: underline !important;
  color: #14a248;
}

sup {
  font-size: 12px;
}

li {
  color: #2F383C;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 18px;
  letter-spacing: -0.32px;
}

.subtitle-p {
  font: inherit;
  font-size: 1rem !important;
  line-height: 22px !important;
  color: #2F383C
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.legal-text {
  font-size: 12px !important;
  line-height: 16px !important;
}

.shell-onboarding {
  font-size: 1rem !important;
}